<template>
  <Layout>
    <div class="page-header">
      <h1>Import transactions from Tink</h1>
    </div>
    <b-card v-if="tinkCode === null" title="CamBank Tink Link">
      <b-card-text
        >This is the Tink Link. Click here to be redirected to Tink, our bank
        aggregator. Choose your bank, insert bank details and you should come
        back to this page with a code.</b-card-text
      >
      <b-button
        :href="
          'https://link.tink.com/1.0/authorize/?client_id=' +
            CamBankConfig.tinkClientId +
            '&redirect_uri=' +
            CamBankConfig.tinkCallback +
            '&scope=accounts:read,transactions:read&market=FR&locale=fr_FR'
        "
        >CamBank Tink Link</b-button
      >
    </b-card>
    <b-card v-else-if="loading" title="CamBank downloads from Tink...">
      <b-spinner></b-spinner>
    </b-card>

    <b-card v-else-if="result === null" title="Start Tink Aggregation">
      <b-card-text
        >We have the code {{ tinkCode }}. Now CamBank can download the
        statements from Tink using your token.
      </b-card-text>
      <b-button @click="callStatementTink"
        >Download statements from Tink</b-button
      >
    </b-card>
    <b-card v-else title="Report">
      <b-card-text>{{ result }}</b-card-text>
      <b-button @click="result = null">Clean</b-button>
    </b-card>
  </Layout>
</template>

<script>
import axios from 'axios'
import Layout from '@layouts/main.vue'
import CamBankConfig from '@src/cambank.config'

export default {
  components: { Layout },
  props: {
    tinkCode: {
      type: String,
      default: null,
    },
  },
  page: {
    title: 'Tink Integration',
    meta: [{ name: 'description', content: 'Download transactions via Tink.' }],
  },
  data() {
    return {
      CamBankConfig: CamBankConfig,
      store: this.$store,
      loading: false,
      result: null,
    }
  },
  methods: {
    callStatementTink: function() {
      this.loading = true
      axios
        .get('/statements/v1/tink/' + this.tinkCode)
        .then((response) => {
          this.result = response.data
        })
        .catch((error) => {
          if ('message' in error.response.data) {
            this.result = `Error ${error.response.status}: ${error.response.data.message}`
          } else {
            this.result = `Error ${error.response.status}: ${error.response.data}`
          }
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>
